import * as FilePond from 'filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import '../../css/uploader/style.css';

FilePond.registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

const normalizeLocale = locale => {
  if (locale.length > 2) {
    return locale;
  }
  return `${locale}-${locale}`;
}

const uploaderConfig = (component, key, defaultValue) => {
  key = 'uploader' + key.charAt(0).toUpperCase() + key.slice(1);
  return component.dataset[key] === undefined
    ? defaultValue
    : component.dataset[key];
}

const init = async (component) => {
  const locale = normalizeLocale(uploaderConfig(component, 'locale', 'en-en'));
  const translations = (await import((`filepond/locale/${locale}.js`))).default;
  const label = normalizeLocale(uploaderConfig(component, 'label', null));
  const previewElement = component.querySelector('.preview');
  const uploaderlement = component.querySelector('.uploader');
  const pathInputDelete = component.querySelector('.delete');
  const deleteButton = component.querySelector('[data-target="deleteButton"]');

  if (null !== deleteButton) {
    deleteButton.addEventListener('click', e => {
      e.preventDefault();
      previewElement.classList.add('hidden');
      uploaderlement.classList.remove('hidden');
      pathInputDelete.value = '1';
    })
  }

  const config = {
    storeAsFile: true,
    credits: null,
    stylePanelLayout: 'integrated',
    ...translations
  }

  if (label !== null) {
    config['labelIdle'] = label;
  }

  component.querySelectorAll('input[type="file"]').forEach(input => {
    FilePond.create(input, config);
  });
}

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-component="uploader"]').forEach(component => {
    init(component);
  });
});

document.addEventListener('toolbox-plugin:update-uploader', (event) => {
  const sourceElement = event.detail !== undefined && event.detail !== null ? event.detail : document;
  sourceElement.querySelectorAll('[data-component="uploader"]').forEach(component => {
    init(component);
  });
});
